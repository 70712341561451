<template>
  <el-drawer :visible.sync="visible" :with-header="false" size="50%">
    <div class="jue-drawer-header">
      <div class="jue-drawer-title">客户轨迹</div>
      <div class="jue-drawer-btns">
        <i @click="visible = false" class="el-icon-close"></i>
      </div>
    </div>

    <div class="jue-drawer-body">
      <el-timeline :reverse="false">
        <el-timeline-item
          :key="index"
          :timestamp="activity.date"
          v-for="(activity, index) in activities"
          placement="top"
        >
          <ul class="activity">
            <li v-if="activity.is_first_income == 1">首次进店</li>
             <li v-if="activity.is_first_income == 2 && activity.is_invite_income == 2">再次进店</li>
            <li v-if="activity.is_first_income == 2 && activity.is_invite_income == 1">邀约进店</li>

            <li v-if="activity.has_trial == 1">试驾</li>
            <li v-if="activity.has_offer == 1">报价</li>
            <li v-if="activity.trace_date">进行了跟踪</li>
            <li
              v-if="activity.trace_date"
            >客户状态：{{$store.state.cfgData[parseInt(activity.client_level)][0]}}</li>
          </ul>
        </el-timeline-item>
      </el-timeline>
    </div>
  </el-drawer>
</template>
<script>
import { cfgById } from '@/unit/unit.js'
export default {
  data() {
    return {
      visible: false,
      activities: []
    }
  },
  methods: {
    cfgData(cid) {
      return cfgById(cid)
    },
    //添加
    view(client_id) {
      this.visible = true
      this.$axios
        .post('/client/timeline', {
          client_id: client_id
        })
        .then(res => {
          if (res.data.code == 200) {
            this.activities = res.data.data
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
.jue-drawer-body {
  padding: 40px;
  height: calc(100vh - 78px);
  overflow-y: auto;
}

.w100 {
  width: 100%;
}

.form {
  display: flex;
  flex-wrap: wrap;
  /deep/ .el-form-item {
    width: 33.33%;
  }
  /deep/ .el-form-item__content {
    font-weight: 700;
  }
}

.form-footer {
  text-align: right;
  width: 50%;
  padding: 0 40px 0 0;
  el-button {
    margin-left: 20px;
  }
}

.activity{
  margin-bottom: 40px;
  padding: 0 0 0 20px;
  margin: 0;
}
</style>