<template>
  <el-drawer :visible.sync="visible" :with-header="false" size="80%">
    <div class="jue-drawer-header">
      <div class="jue-drawer-title">客户档案</div>
      <div class="jue-drawer-btns">
        <i @click="visible = false" class="el-icon-close"></i>
      </div>
    </div>

    <div class="jue-drawer-body">
      <el-form class="form" label-position="right" label-width="120px" ref="form">
        <el-form-item label="客户姓名: ">
          <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="form.realname"></el-input>
        </el-form-item>
        <el-form-item label="手机电话: ">{{listData.mobile}}</el-form-item>
        <el-form-item
          label="最近跟踪时间: "
        >{{listData.last_trace_date == '' ? "无跟踪" : listData.last_trace_date}}</el-form-item>
        <el-form-item label="销售顾问: ">
          <span
            v-if="$store.state.userList[listData.seller_uid] != undefined"
          >{{$store.state.userList[listData.seller_uid][0]}}</span>
        </el-form-item>
        <el-form-item label="来电/店: ">{{listData.income_type == '1' ? '来店' : '来电'}}</el-form-item>
        <el-form-item label="进店日期: ">{{listData.income_date}}</el-form-item>
        <el-form-item label="来访时间段: ">{{cfgData(listData.income_time_section)}}</el-form-item>
        <el-form-item label="进离店时间: ">{{listData.income_time_begin}} - {{listData.income_time_end}}</el-form-item>
        <el-form-item label="留店时长: ">{{listData.income_time_duration}} 分钟</el-form-item>
        <el-form-item label="来店人数: ">{{listData.income_number}} 人</el-form-item>
        <el-form-item label="客户类型: ">{{cfgData(listData.client_type)}}</el-form-item>
        <el-form-item label="微信号: ">{{listData.wechat}}</el-form-item>
        <el-form-item label="性别: ">{{listData.sex == '1' ? '男' : '女'}}</el-form-item>
        <el-form-item label="客户属性: ">{{listData.is_first_income == '1' ? '首次' : '再次'}}</el-form-item>
        <el-form-item label="区域: ">{{cfgData(listData.region)}}</el-form-item>
        <el-form-item label="是否入座: ">{{listData.has_seat == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="是否报价: ">{{listData.has_offer == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="是否重点客户: ">
          <el-radio :label="1" v-model="form.is_vip">是</el-radio>
          <el-radio :label="2" v-model="form.is_vip">否</el-radio>
        </el-form-item>
        <el-form-item label="是否试驾: ">{{listData.has_trial == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="试驾备注: ">{{listData.trial_memo}}</el-form-item>
        <el-form-item label="现提车辆: ">{{listData.has_pick == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="来店目的: ">{{cfgData(listData.income_purpose)}}</el-form-item>
        <el-form-item label="意向车型: " style="width: 100%">
          <el-cascader
            :options="carTypeOptions()"
            :props="{ checkStrictly: true }"
            @change="changeCarType"
            clearable
            style="width: 100%"
            v-model="car_type"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="变速箱类型: " style="width: 50%">
          <el-select placeholder="请选择" style="width: 100%" v-model="form.transmission">
            <template v-for="(item, index) in $store.state.cfgList.gearboxType">
              <el-option :key="index" :label="item.f1" :value="item.sys_id" v-if="item.status == 1"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="需求排量: " style="width: 50%">
          <el-select placeholder="请选择" style="width: 100%" v-model="form.displacement">
            <template v-for="(item, index) in $store.state.cfgList.displacement">
              <el-option :key="index" :label="item.f1" :value="item.sys_id" v-if="item.status == 1"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="外观颜色: " style="width: 50%">
          <el-select placeholder="请选择" style="width: 100%" v-model="form.outer_color">
            <template v-for="(item, index) in $store.state.cfgList.outsideColor">
              <el-option :key="index" :label="item.f1" :value="item.sys_id" v-if="item.status == 1"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="内饰颜色: " style="width: 50%">
          <el-select placeholder="请选择" style="width: 100%" v-model="form.inner_color">
            <template v-for="(item, index) in $store.state.cfgList.insideColor">
              <el-option :key="index" :label="item.f1" :value="item.sys_id" v-if="item.status == 1"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="线索渠道: ">{{cfgData(listData.clue_source)}}</el-form-item>
        <el-form-item label="营销分析: ">{{cfgData(listData.marketing_analysis)}}</el-form-item>
        <el-form-item label="客户类别: ">{{cfgData(listData.client_cate)}}</el-form-item>
        <el-form-item label="客户有效性: ">{{listData.has_effective == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="购车类型: ">{{cfgData(listData.buy_type)}}</el-form-item>
        <el-form-item label="二手车型: ">{{listData.used_model}}</el-form-item>
        <el-form-item label="二手车到店: ">{{listData.used_arrived == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="是否勘察: ">{{listData.used_surveyed == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="评估师: ">{{listData.valuator}}</el-form-item>
        <el-form-item label="简述: " style="width: 100%">{{listData.demand_memo}}</el-form-item>
      </el-form>
    </div>

    <div class="form-footer">
      <div>
        <el-button @click="clientEdit" type="primary">更新客户需求</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { cfgById, getCarType } from '@/unit/unit.js'
export default {
  data() {
    return {
      visible: false,
      listData: {},
      car_type: '',
      form: {
        client_id: 0,
        target_model: '',
        attribute: '',
        transmission: '',
        displacement: '',
        outer_color: '',
        inner_color: '',
        realname: '',
        is_vip: ''
      }
    }
  },
  methods: {
    changeCarType() {
      if (this.car_type.length == 0) {
        this.form.target_model = ''
        this.form.attribute = ''
      }
      if (this.car_type.length == 1) {
        this.form.target_model = this.car_type[0]
        this.form.attribute = ''
      }
      if (this.car_type.length == 2) {
        this.form.target_model = this.car_type[0]
        this.form.attribute = this.car_type[1]
      }
    },
    carTypeOptions() {
      return getCarType()
    },
    cfgData(cid) {
      return cfgById(cid)
    },
    //添加
    view(id) {
      this.form.client_id = id
      this.visible = true
      this.$axios
        .post('/client/detail', {
          client_id: id
        })
        .then(res => {
          this.listData = res.data.data
          this.form.realname = res.data.data.realname
          this.form.is_vip = res.data.data.is_vip

          this.form.target_model =
            res.data.data.target_model == ''
              ? ''
              : parseInt(res.data.data.target_model)
          this.form.attribute =
            res.data.data.attribute == ''
              ? ''
              : parseInt(res.data.data.attribute)
          if (this.form.attribute) {
            this.car_type = [this.form.target_model, this.form.attribute]
          } else {
            this.car_type = [this.form.target_model]
          }

          this.form.transmission =
            res.data.data.transmission == ''
              ? ''
              : parseInt(res.data.data.transmission)
          this.form.displacement =
            res.data.data.displacement == ''
              ? ''
              : parseInt(res.data.data.displacement)
          this.form.outer_color =
            res.data.data.outer_color == ''
              ? ''
              : parseInt(res.data.data.outer_color)
          this.form.inner_color =
            res.data.data.inner_color == ''
              ? ''
              : parseInt(res.data.data.inner_color)
        })
    },

    clientEdit(type) {
      this.$axios.post('/client/edit', this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.jue-drawer-body {
  padding: 40px;
  height: calc(100vh - 78px);
  overflow-y: auto;
}

.w100 {
  width: 100%;
}

.form {
  display: flex;
  flex-wrap: wrap;
  /deep/ .el-form-item {
    width: 33.33%;
  }
  /deep/ .el-form-item__content {
    font-weight: 700;
  }
}

.form-footer {
  text-align: right;
  width: 80%;
  padding: 0 40px 0 0;
  el-button {
    margin-left: 20px;
  }
}
</style>