<template>
  <div class="search">
    <el-form>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-form-item label="销售顾问">
            <el-select
              clearable
              filterable
              placeholder="请选择"
              v-model="seachKey.seller_uid"
            >
              <template v-for="(item, key) in $store.state.userList">
                <el-option
                  :key="key"
                  :label="item[0]"
                  :value="parseInt(key)"
                  v-if="item[3] == 1"
                ></el-option>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="客户姓名">
            <el-input
              placeholder="请输入"
              suffix-icon="el-icon-edit"
              v-model.trim="seachKey.realname"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="手机号码">
            <el-input
              placeholder="请输入"
              suffix-icon="el-icon-edit"
              v-model.trim="seachKey.mobile"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="意向车型">
            <el-cascader
              :options="carTypeOptions()"
              :props="{ checkStrictly: true }"
              @change="changeCarType"
              style="width: 100%"
              v-model="car_type"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="进店日期">
            <el-date-picker
              @change="setIncomeDate"
              align="right"
              end-placeholder="结束日期"
              format="MM月dd日"
              range-separator="-"
              start-placeholder="开始日期"
              type="daterange"
              unlink-panels
              v-model="income_date_begin_end"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="label-block" label=" ">
            <el-button
              @click="handleSearch"
              icon="el-icon-search"
              type="primary"
              >搜索</el-button
            >
            <el-button @click="resetSearch">重置</el-button>
            <el-button @click="dialog = true" type="text">高级搜索</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- 高级搜索框 -->
    <el-dialog :visible.sync="dialog" title="高级搜索">
      <el-form
        :model="seachKey"
        class="form"
        label-position="top"
        label-width="100px"
        ref="form"
        status-icon
      >
        <el-form-item label="销售顾问">
          <el-select
            clearable
            filterable
            placeholder="请选择"
            v-model="seachKey.seller_uid"
          >
            <template v-for="(item, key) in $store.state.userList">
              <el-option
                :key="key"
                :label="item[0]"
                :value="parseInt(key)"
                v-if="item[3] == 1"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="客户姓名">
          <el-input
            placeholder="请输入"
            suffix-icon="el-icon-edit"
            v-model.trim="seachKey.realname"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input
            placeholder="请输入"
            suffix-icon="el-icon-edit"
            v-model.trim="seachKey.mobile"
          ></el-input>
        </el-form-item>
        <el-form-item label="意向车型">
          <el-cascader
            :options="carTypeOptions()"
            :props="{ checkStrictly: true }"
            @change="changeCarType"
            v-model="car_type"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="进店日期">
          <el-date-picker
            @change="setIncomeDate"
            align="right"
            end-placeholder="结束日期"
            format="MM月dd日"
            range-separator="-"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            v-model="income_date_begin_end"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="线索渠道">
          <el-select placeholder="请选择" v-model="seachKey.clue_source">
            <template v-for="(item, index) in $store.state.cfgList.channel">
              <el-option
                :key="index"
                :label="item.f1"
                :value="item.sys_id"
                v-if="item.status == 1"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="购车类型">
          <el-select placeholder="请选择" v-model="seachKey.buy_type">
            <template v-for="(item, index) in $store.state.cfgList.buyTimes">
              <el-option
                :key="index"
                :label="item.f1"
                :value="item.sys_id"
                v-if="item.status == 1"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="客户级别" prop="client_level">
          <el-select placeholder="请选择" v-model="seachKey.client_level">
            <el-option
              :value="-1"
              disabled
              label="(空)"
              v-if="seachKey.client_level == -1"
            ></el-option>
            <el-option
              :key="index"
              :label="item.f1"
              :value="item.sys_id"
              v-for="(item, index) in $store.state.cfgList.customerLevel"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邀约到店">
          <el-select placeholder="请选择" v-model="seachKey.is_invite_income">
            <el-option label="(空)" value></el-option>
            <el-option :value="1" label="是"></el-option>
            <el-option :value="2" label="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户有效性">
          <el-select placeholder="请选择" v-model="seachKey.has_effective">
            <el-option label="(空)" value></el-option>
            <el-option :value="1" label="是"></el-option>
            <el-option :value="2" label="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下次回访日期">
          <el-date-picker
            @change="setBeginEnd"
            align="right"
            end-placeholder="结束日期"
            format="MM月dd日"
            range-separator="-"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            v-model="next_trace_date"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="邀约日期">
          <el-date-picker
            @change="setInviteDate"
            align="right"
            end-placeholder="结束日期"
            format="MM月dd日"
            range-separator="-"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            v-model="invite_data"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="跟踪日期">
          <el-date-picker
            @change="setTraceDate"
            align="right"
            end-placeholder="结束日期"
            format="MM月dd日"
            range-separator="-"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            v-model="trace_data"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="是否检核">
          <el-select placeholder="请选择" v-model="seachKey.has_check">
            <el-option label="(空)" value></el-option>
            <el-option :value="1" label="是"></el-option>
            <el-option :value="2" label="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="检核日期">
          <el-date-picker
            @change="setCheckDate"
            align="right"
            end-placeholder="结束日期"
            format="MM月dd日"
            range-separator="-"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            v-model="check_date"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="订车日期">
          <el-date-picker
            @change="setBookDate"
            align="right"
            end-placeholder="结束日期"
            format="MM月dd日"
            range-separator="-"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            v-model="book_date"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="交车日期">
          <el-date-picker
            @change="setDeliverDate"
            align="right"
            end-placeholder="结束日期"
            format="MM月dd日"
            range-separator="-"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            v-model="deliver_date"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="战败日期">
          <el-date-picker
            @change="setFallDate"
            align="right"
            end-placeholder="结束日期"
            format="MM月dd日"
            range-separator="-"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            v-model="fall_date"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span class="dialog-footer" slot="footer">
        <el-button @click="resetSearch">重置</el-button>
        <el-button @click="handleSearch" type="primary">搜索</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getCarType } from '@/unit/unit.js'
export default {
  props: ['type'],
  data () {
    return {
      dialog: false,
      car_type: '',
      income_date_begin_end: [],
      next_trace_date: [],
      invite_data: [],
      trace_data: [],
      check_date: [],
      book_date: [],
      deliver_date: [],
      fall_date: [],
      seachKey: {
        page: 1,
        count: 15
      },
    }
  },
  mounted () {
    if (localStorage.getItem('searchKey')) {
      this.seachKey = JSON.parse(localStorage.getItem('searchKey'))
    }
  },
  methods: {
    setBeginEnd () {
      if (this.next_trace_date) {
        this.seachKey.next_trace_date_begin = this.next_trace_date[0]
        this.seachKey.next_trace_date_end = this.next_trace_date[1]
      } else {
        this.seachKey.next_trace_date_begin = ''
        this.seachKey.next_trace_date_end = ''
      }
    },
    setIncomeDate () {
      if (this.income_date_begin_end) {
        this.seachKey.income_date_begin = this.income_date_begin_end[0]
        this.seachKey.income_date_end = this.income_date_begin_end[1]
      } else {
        this.seachKey.income_date_begin = ''
        this.seachKey.income_date_end = ''
      }
    },
    setInviteDate () {
      if (this.invite_data) {
        this.seachKey.invite_income_date_begin = this.invite_data[0]
        this.seachKey.invite_income_date_end = this.invite_data[1]
      } else {
        this.seachKey.invite_income_date_begin = ''
        this.seachKey.invite_income_date_end = ''
      }
    },
    setTraceDate () {
      if (this.trace_data) {
        this.seachKey.trace_date_begin = this.trace_data[0]
        this.seachKey.trace_date_end = this.trace_data[1]
      } else {
        this.seachKey.trace_date_begin = ''
        this.seachKey.trace_date_end = ''
      }
    },
    setCheckDate () {
      if (this.check_date) {
        this.seachKey.check_date_begin = this.check_date[0]
        this.seachKey.check_date_end = this.check_date[1]
      } else {
        this.seachKey.check_date_begin = ''
        this.seachKey.check_date_end = ''
      }
    },
    setBookDate () {
      if (this.book_date) {
        this.seachKey.book_date_begin = this.book_date[0]
        this.seachKey.book_date_end = this.book_date[1]
      } else {
        this.seachKey.book_date_begin = ''
        this.seachKey.book_date_end = ''
      }
    },
    setDeliverDate () {
      if (this.deliver_date) {
        this.seachKey.deliver_date_begin = this.deliver_date[0]
        this.seachKey.deliver_date_end = this.deliver_date[1]
      } else {
        this.seachKey.deliver_date_begin = ''
        this.seachKey.deliver_date_end = ''
      }
    },
    setFallDate () {
      if (this.fall_date) {
        this.seachKey.fall_date_begin = this.fall_date[0]
        this.seachKey.fall_date_end = this.fall_date[1]
      } else {
        this.seachKey.fall_date_begin = ''
        this.seachKey.fall_date_end = ''
      }
    },
    changeCarType () {
      if (this.car_type.length == 0) {
        this.seachKey.target_model = ''
        delete this.seachKey.attribute
      }
      if (this.car_type.length == 1) {
        this.seachKey.target_model = this.car_type[0]
        delete this.seachKey.attribute
      }
      if (this.car_type.length == 2) {
        this.seachKey.target_model = this.car_type[0]
        this.seachKey.attribute = this.car_type[1]
      }
    },
    carTypeOptions () {
      return getCarType()
    },
    handleSearch () {
      //缓存搜索条件
      localStorage.setItem('searchKey', JSON.stringify(this.seachKey))

      localStorage.removeItem('page')
      for (var propName in this.seachKey) {
        if (this.seachKey[propName] === '') {
          delete this.seachKey[propName]
        }
      }

      this.$parent.setSearchKey(this.seachKey)
      this.dialog = false
    },
    resetSearch () {
      this.car_type = ''
      this.income_date_begin_end = []
      this.next_trace_date = []
      this.invite_data = []
      this.trace_data = []
      this.check_date = []
      this.book_date = []
      this.deliver_date = []
      this.fall_date = []
      this.seachKey = {}
      this.seachKey.count = 15
      this.$parent.setSearchKey(this.seachKey)
    },
  },
  watch: {
    $route (to, from) {
      if (localStorage.getItem('searchKey')) {
        this.seachKey = JSON.parse(localStorage.getItem('searchKey'))
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  /deep/ .el-form-item {
    width: calc(33.33% - 20px);
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.search {
  /deep/ .el-cascader,
  /deep/ .el-select,
  /deep/ .el-input__inner {
    width: 100%;
  }
}
</style>