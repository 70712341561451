<template>
  <div>
    <el-tooltip :content="loadingText" class="item" effect="dark" placement="top">
      <i @click="exportData" class="el-icon-download" v-if="!loading"></i>
      <i class="el-icon-loading" v-if="loading"></i>
    </el-tooltip>
  </div>
</template>
<script>
import { export_json_to_excel } from '@/unit/Export2Excel.js'
export default {
  props: ['searchKey'],
  data() {
    return {
      loading: false,
      loadingText: '导出数据',
      listData: [],
      key: null,
    }
  },
  methods: {
    exportData() {
      this.loading = true
      this.loadingText = '导出中'

      this.key = Object.assign({}, this.searchKey)
      this.key.count = 999999
      this.key.page = 1

      this.$axios.post('/client/lists', this.key).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data.list.length > 0) {
            let listData = res.data.data.list
            listData.map((item, index) => {
              if (item.client_level) {
                listData[index].client_level_text = this.$store.state.cfgData[
                  item.client_level
                ][0]
              }

              listData[index].is_invite_income_text = item.is_invite_income == '1' ? '是' : '否'

              listData[index].seller_text = this.$store.state.userList[
                item.seller_uid
              ][0]
              listData[index].income_type_text =
                item.income_type == '1' ? '来店' : '来电'

              listData[index].income_time_begin_end =
                item.income_time_begin + ' - ' + item.income_time_end

              listData[
                index
              ].income_time_section_text = this.$store.state.cfgData[
                item.income_time_section
              ][0]
              listData[index].is_first_income_text =
                item.is_first_income == '1' ? '首次' : '再次'
              if (item.region != '') {
                listData[index].region_text = this.$store.state.cfgData[
                  item.region
                ][0]
              }
              listData[index].sex_text = item.sex == '1' ? '男' : '女'
              listData[index].has_seat_text = item.has_seat == '1' ? '是' : '否'
              listData[index].has_offer_text =
                item.has_offer == '1' ? '是' : '否'
              listData[index].has_trial_text =
                item.has_trial == '1' ? '是' : '否'
              listData[index].is_vip_text = item.is_vip == '1' ? '是' : '否'
              listData[index].has_pick_text = item.has_pick == '1' ? '是' : '否'
              if (item.income_purpose != '') {
                listData[index].income_purpose_text = this.$store.state.cfgData[
                  item.income_purpose
                ][0]
              }
              if (item.client_type != '') {
                listData[index].client_type_text = this.$store.state.cfgData[
                  item.client_type
                ][0]
              }
              if (item.target_model != '') {
                listData[index].target_model_text = this.$store.state.cfgData[
                  item.target_model
                ][0]
              }
              if (item.transmission != '') {
                listData[index].transmission_text = this.$store.state.cfgData[
                  item.transmission
                ][0]
              }
              if (item.displacement != '') {
                listData[index].displacement_text = this.$store.state.cfgData[
                  item.displacement
                ][0]
              }
              if (item.outer_color != '') {
                listData[index].outer_color_text = this.$store.state.cfgData[
                  item.outer_color
                ][0]
              }
              if (item.inner_color != '') {
                listData[index].inner_color_text = this.$store.state.cfgData[
                  item.inner_color
                ][0]
              }
              if (item.clue_source != '') {
                listData[index].clue_source_text = this.$store.state.cfgData[
                  item.clue_source
                ][0]
              }
              if (item.marketing_analysis != '') {
                listData[
                  index
                ].marketing_analysis_text = this.$store.state.cfgData[
                  item.marketing_analysis
                ][0]
              }
              if (item.client_cate != '') {
                listData[index].client_cate_text = this.$store.state.cfgData[
                  item.client_cate
                ][0]
              }

              if (item.trace_status) {
                listData[index].trace_status_text = this.$store.state.cfgData[
                  item.trace_status
                ][0]
              }

              listData[index].has_effective_text =
                item.has_effective == '1' ? '是' : '否'
              if (item.buy_type != '') {
                listData[index].buy_type_text = this.$store.state.cfgData[
                  item.buy_type
                ][0]
              }
              listData[index].used_arrived_text =
                item.used_arrived == '1' ? '是' : '否'
              listData[index].used_surveyed_text =
                item.used_surveyed == '1' ? '是' : '否'
            })
            this.listData = listData
            this.getExcel(this.listData)
          } else {
            this.$message.error('当前无导出数据')
            this.loading = false
            this.loadingText = '导出数据'
          }
        }
      })
    },

    getExcel(res) {
      require.ensure([], () => {
        const tHeader = [
          '客户姓名',
          '手机电话',
          '进店日期',
          '客户级别',
          '跟踪次数',
          '邀约到店',
          '最近跟踪时间',
          '销售顾问',
          '来店/电',
          '来访时间段',
          '进离店时间',
          '留店时长',
          '来店人数',
          '客户类型',
          '微信号',
          '性别',
          '客户属性',
          '区域',
          '是否入座',
          '是否报价',
          '是否试驾',
          '是否重点客户',
          '再提车辆',
          '试驾备注',
          '来店目的',
          '意向车型',
          '变速箱类型',
          '需求排量',
          '外观颜色',
          '内饰颜色',
          '线索渠道',
          '营销分析',
          '客户类别',
          '客户有效性',
          '购车类型',
          '二手车型',
          '二手车到店',
          '是否勘察',
          '评估师',
          '简述',
          '促进结果',
          '跟踪描述',
          '订车日期',
          '交车日期',
          '战败日期',
          '失控日期',
        ]
        const filterVal = [
          'realname',
          'mobile',
          'income_date',
          'client_level_text',
          'trace_count',
          'is_invite_income_text',
          'last_trace_date',
          'seller_text',
          'income_type_text',
          'income_time_section_text',
          'income_time_begin_end',
          'income_time_duration',
          'income_number',
          'client_type_text',
          'wechat',
          'sex_text',
          'is_first_income_text',
          'region_text',
          'has_seat_text',
          'has_offer_text',
          'has_trial_text',
          'is_vip_text',
          'has_pick_text',
          'trial_memo',
          'income_purpose_text',
          'target_model_text',
          'transmission_text',
          'displacement_text',
          'outer_color_text',
          'inner_color_text',
          'clue_source_text',
          'marketing_analysis_text',
          'client_cate_text',
          'has_effective_text',
          'buy_type_text',
          'used_model',
          'used_arrived_text',
          'used_surveyed_text',
          'valuator',
          'demand_memo',
          'trace_status_text',
          'trace_memo',
          'book_date',
          'deliver_date',
          'fall_date',
          'lose_date',
        ]
        const list = this.listData
        const data = this.formatJson(filterVal, list)
        export_json_to_excel(tHeader, data, '客户档案')

        this.loading = false
        this.loadingText = '导出数据'
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    },
  },
}
</script>