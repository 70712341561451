<template>
  <el-drawer :visible.sync="visible" :with-header="false" size="600px">
    <div class="jue-drawer-header">
      <div class="jue-drawer-title">销售变更</div>
      <div class="jue-drawer-btns">
        <i @click="visible = false" class="el-icon-close"></i>
      </div>
    </div>

    <div class="jue-drawer-body">
      <el-table :data="sellerList" row-key="id" style="width: 100%">
        <el-table-column label="客户姓名" prop="realname"></el-table-column>
        <el-table-column label="原销售顾问" prop="seller_uid">
          <template slot-scope="scope">
            <span>{{ $store.state.userList[scope.row.seller_uid][0] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form
            :model="form"
            :rules="rules"
            class="form"
            label-position="top"
            ref="form"
            style="padding: 0 80px;"
          >
            <el-form-item label="新销售顾问" prop="seller_uid">
              <el-select filterable placeholder="请选择" v-model="form.seller_uid">
                <template v-for="(item, key) in $store.state.userList">
                  <el-option
                    :key="key"
                    :label="item[0]"
                    :value="parseInt(key)"
                    v-if="item[3] == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="备注">
              <el-input :rows="3" placeholder="请输入" type="textarea" v-model="form.memo"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div class="form-footer">
      <div>
        <el-button @click="visible = false">取 消</el-button>
        <el-button @click="submitForm('form')" type="primary">提 交</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      sellerList: [],
      formData: [],
      form: {
        client_ids: [],
        seller_uid: '',
        memo: ''
      },
      rules: {
        seller_uid: [{ required: true, message: '必需选择', trigger: 'change' }]
      }
    }
  },
  methods: {
    initData(data) {
      this.form.client_ids = []
      this.form.seller_uid = ''
      this.form.memo = ''

      this.sellerList = data
      this.sellerList.map(item => {
        this.form.client_ids.push(item.client_id)
      })
      this.visible = true
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios.post('/client/change_seller', this.form).then(res => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.$emit('getListData')
              this.visible = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.jue-drawer-body {
  padding: 40px 0 0;

  /deep/ .el-select {
    width: 100%;
  }

  /deep/ .el-col {
    line-height: 36px;
    padding: 15px 0;
  }
}

.form-footer {
  text-align: right;
  width: 600px;
  padding: 0 40px 0 0;
  el-button {
    margin-left: 20px;
  }
}
</style>